<template>
    <div class="modal-auto-text">
        <div class="head flex-row flex-between items-start">
            <div class="title" v-html="title" />
        </div>
        <div class="top" v-html="$translate('ADD_TEMPLATE_TXT')" />
        <div class="flex-row">
            <div class="label flex-wrap" v-html="$translate('TITLE')" />
            <div
                class="input-wrapper flex-row items-center"
                :class="{ focus: titleFocused, 'c-black': autoText.title }"
            >
                <span class="flex-wrap" v-html="'/'" />
                <input
                    ref="title"
                    :placeholder="$translate('PLACEHOLDER_ADD_TEMPLATE_TITLE')"
                    v-model="autoText.title"
                    @focus="titleFocused = true"
                    @blur="titleFocused = false"
                    maxlength="8"
                />
            </div>
        </div>
        <div class="flex-row">
            <div class="label flex-wrap" v-html="$translate('CONTENT')" />
            <div class="textarea-wrapper p-relative">
                <textarea
                    :placeholder="$translate('PLACEHOLDER_ADD_TEMPLATE_CONTENT')"
                    v-model="autoText.content"
                    maxlength="1000"
                />
                <transition name="slide-up">
                    <Emoji
                        v-if="showEmoji"
                        class="emoji-wrapper p-absolute"
                        @onClickEmoji="onClickEmoji"
                        @close="showEmoji = false"
                    />
                </transition>
                <img
                    @click="showEmoji = !showEmoji"
                    class="auto-texts-function-icon"
                    @mouseover="overEmoji = true"
                    @mouseleave="overEmoji = false"
                    :src="emojiSrc"
                />
            </div>
        </div>
        <div class="buttons bottom">
            <div class="flex-row">
                <button @click="$emit('close', 0)" class="btn-default" v-html="$translate('CANCEL')" />
                <button
                    ref="confirm"
                    @click="onConfirm"
                    :class="disableConfirm ? 'disabled' : 'btn-primary'"
                    v-html="confirmLabel"
                />
            </div>
        </div>
    </div>
</template>
<script>
import autoTextService from '@/services/auto-text'

export default {
    name: 'ModalAutoText',
    props: ['options'],
    data: () => ({
        autoText: {
            title: null,
            content: null,
        },
        titleFocused: false,
        showEmoji: false,
        overEmoji: false,
    }),
    computed: {
        disableConfirm() {
            return !this.autoText.title || !this.autoText.content
        },
        title() {
            if (!this.options) return this.$translate('ADD_TEMPLATE')

            return this.$translate('EDIT_TEMPLATE')
        },
        confirmLabel() {
            if (!this.options) return this.$translate('ADD')

            return this.$translate('EDIT')
        },
        emojiSrc() {
            if (this.showEmoji) {
                return require(`@/assets/images/emoji_active.svg`)
            } else if (this.overEmoji) {
                return require(`@/assets/images/emoji_hover.svg`)
            } else {
                return require(`@/assets/images/emoji_inactive.svg`)
            }
        },
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            const dom = this.$refs.title
            if (dom) {
                dom.focus()
            }

            if (!this.options || !this.options.autoText) {
                return
            }

            this.autoText = this.options.autoText
        },
        async create() {
            try {
                await autoTextService.post(this.autoText)
                this.$toast.success('CREATED')
                this.$emit('close')
            } catch (e) {
                this.$toast.error(e.data)
            }
        },
        async update() {
            try {
                await autoTextService.put(this.autoText)
                this.$toast.success('UPDATED')
                this.$emit('close')
            } catch (e) {
                this.$toast.error(e.data)
            }
        },
        onConfirm() {
            if (this.disableConfirm) {
                const dom = this.$refs.confirm
                if (dom) {
                    this.$animate.shake(dom)
                }
                return
            }

            if (!this.options) {
                this.create()
                return
            }

            this.update()
        },
        onClickEmoji(value) {
            this.showEmoji = false
            if (!this.autoText.content) this.autoText.content = ''
            this.autoText.content += value

            if (this.dom) this.dom.focus()
        },
    },
}
</script>
